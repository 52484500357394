import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import { router } from "./router/index";
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fas);
library.add(fab);
library.add(far);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueAxios, axios)
Vue.use(Vuelidate);

Vue.use(VueCookies);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");