<template>
  <div id="app">
    <top-nav-bar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import TopNavBar from "./components/TopNavBar.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    TopNavBar,
    Footer,
  },
};
</script>

<style lang="scss"></style>
