<template>
  <nav
    id="NavBarGlobal"
    class="navbar is-white is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <!--(NAVBAR BRAND) Es el navbarburger en dispositivos  -->
      <router-link @click="Img" class="navbar-item">
        <img src="../assets/img/Logo1.png" />
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        @click="isOpen = !isOpen"
        v-bind:class="{ 'is-active': isOpen }"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" v-bind:class="{ 'is-active': isOpen }">
      <div class="navbar-start">
        <router-link to="./" class="navbar-item">
          <h1 class="Title">IMPRESIONES</h1>
        </router-link>
        <router-link to="/categories" class="navbar-item">
          <h5 class="Subtitle">Catalogo</h5>
        </router-link>
        <router-link to="/promotions" class="navbar-item">
          <h5 class="Subtitle">Promociones</h5>
        </router-link>
        <router-link to="/aboutUS" class="navbar-item">
          <h5 class="Subtitle">Sobre Nosotros</h5>
        </router-link>
        <router-link to="/contactUs" class="navbar-item">
          <h5 class="Subtitle">Contactanos</h5>
        </router-link>

        <div class="SocialContainer">
          <ul class="social-icons">
            <li id="facebook">
              <a href="https://www.facebook.com/delriosolutions" target="_blank"
                ><i class="fa fa-facebook"></i
              ></a>
            </li>
            <li id="instagram">
              <a href="https://delriosolutions.com.mx/" target="_blank"
                ><i class="fas fa-globe"></i
              ></a>
            </li>
            <li id="whatsapp">
              <a href="https://wa.link/4h2am8" target="_blank"
                ><i class="fa fa-whatsapp"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "topnavbar",
  data: function() {
    return {
      isOpen: false,
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
#NavBarGlobal {
  width: 100%;
  height: 8%;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2%;
}
.Logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}
.navbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.Title {
  font-style: bold;
  font-weight: bold;
  font-size: 2.4em;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.Subtitle {
  font-style: bold;
  font-weight: bold;
  font-size: 1.1em;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #8c9859;
}
.SocialContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icons {
  display: flex;
}
.social-icons li {
  display: flex;
  position: relative;
  margin: 20px;
  font-size: 1.2em;
}
.social-icons i {
  color: white;
  position: absolute;
  top: 12px;
  left: 14.5px;
  transition: all 265ms ease-out;
}
.social-icons a:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, #000000, #707070);
  transition: all 265ms ease-out;
}
.social-icons a:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}
.social-icons a:hover i {
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  color: #000000;
  background: -webkit-linear-gradient(45deg, #000000, #000000);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}
.navbar-item {
  position: relative;
  position: relative;
  background-color: white;
  border-radius: 10px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    transition: all 0.4s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: 1px;
    background-color: white;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}
</style>
