<template>
    <footer class="footer">
      <div class="content has-text-centered"></div>
    </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  background-image: url("../assets/img/Background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
