import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'MainMenu',
        component: () =>
            import ( /* webpackChunkName: "catalogo" */ '../components/MainMenu.vue'),
    },
    {
        path: '/catalogue',
        name: 'Catalogue',
        component: () =>
            import ( /* webpackChunkName: "catalogo" */ '../components/Catalogue.vue'),
    },
    {
        path: '/promotions',
        name: 'Promotions',
        component: () =>
            import ( /* webpackChunkName: "promociones" */ '../components/Promotions.vue'),
    },
    {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () =>
            import ( /* webpackChunkName: "sobre nosotros" */ '../components/AboutUs.vue'),
    },
    {
        path: '/contactUs',
        name: 'ContactUs',
        component: () =>
            import ( /* webpackChunkName: "contactanos" */ '../components/ContactUs.vue'),
    },
    {
        path: '/categories',
        name: 'Categories',
        component: () =>
            import ( /* webpackChunkName: "pie de pagina" */ '../components/Categories.vue'),
    },
];

export const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});